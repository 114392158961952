company_id<template lang="pug">
  .plan-list
    template(v-if="selectedPlanId")
      Form(
        ref="selectedPlan"
        :plan-id="selectedPlanId"
        @back="unselectPlan"
      )
    template(v-else)
      .container-fluid
        AppOverlayLoader(:state="loading")
        Filters(
          :companies="companies"
          :inventory-groups="inventoryGroups"
          :shops="shops"
          :car-classes="carClasses"
          :filters="filters"
          :show-more-filters="showMoreFilters"
          @switch-more-filters="switchMoreFilters"
          @change-filters="changeFilters"
          @clear-filters="clearFilters"
        )
        .table
          VueGoodTable(
            :rows="plans"
            :columns="columns"
            @on-sort-change="changeSorting"
            @on-row-click="selectPlan"
          )
            template(v-slot:table-column="props")
              span(:title="props.column.label") {{ $t(props.column.label) }}
            template(v-slot:table-row="{ column, row }")
              template(v-if="column.field === 'status'")
                .status(:class=" `${row.status}` ")
                  FaIcon.icon(
                    v-if="row.status === 'suspended'"
                    icon="exclamation-triangle"
                  )
                  span
                    | {{ statusLabel(row) }}
        .paginator
          AppPagination(
            :current-page="pagination.current_page"
            :total="pagination.total_count"
            :per-page="pagination.per_page"
            @change-pagination-data="changePagination"
          )
</template>

<script>
  // mixins
  import withStoreModule from "@/mixins/withStoreModule"
  import withConfirmation from "@/mixins/withConfirmation"

  // components
  import { VueGoodTable } from "vue-good-table"

  // misc
  import { isEqual, isEmpty, find } from "lodash-es"
  import { columns } from "./planTableConfig"
  import "vue-good-table/dist/vue-good-table.css"
  import { appDebounce } from "@/helpers/common"

  // store modules
  import planListModule from "@/config/store/ota_management/plan_list"
  import companiesModule from "@/config/store/ota_management/companies"
  import inventoryGroupsModule from "@/config/store/ota_management/inventory_groups"
  import shopsModule from "@/config/store/ota_management/shops"
  import carClassesModule from "@/config/store/ota_management/car_classes"

  const planListMixin = withStoreModule(planListModule, {
    name: "otaManagementPlanListMixin",
    resetState: true,
    readers: {
      plans: "items",
      loading: "loading",
      filters: "filters",
      pagination: "pagination",
      sorting: "sorting"
    },
    mutations: {
      setPagination: "SET_PAGINATION_DATA",
      resetCurrentPage: "RESET_CURRENT_PAGE",
      setFilters: "SET_FILTERS",
      resetFilters: "RESET_FILTERS",
      setSorting: "SET_SORTING"
    },
    actions: {
      fetchPlans: "FETCH_ITEMS"
    }
  })

  const companiesMixin = withStoreModule(companiesModule, {
    name: "otaManagementCompaniesMixin",
    readers: { companies: "items" },
    actions: { fetchCompanies: "FETCH_ITEMS" }
  })

  const inventoryGroupsMixin = withStoreModule(inventoryGroupsModule, {
    name: "otaManagementInventoryGroupsMixin",
    readers: { inventoryGroups: "items" },
    actions: { fetchInventoryGroups: "FETCH_ITEMS" }
  })

  const shopsMixin = withStoreModule(shopsModule, {
    name: "otaManagementShopsMixin",
    readers: { shops: "items" },
    actions: { fetchShops: "FETCH_ITEMS" }
  })

  const carClassesMixin = withStoreModule(carClassesModule, {
    name: "otaManagementCarClassesMixin",
    readers: { carClasses: "items" },
    actions: { fetchCarCLasses: "FETCH_ITEMS" }
  })

  export default {
    components: {
      VueGoodTable,
      Form: () => import("./Form"),
      Filters: () => import("./Filters"),
      AppOverlayLoader: () => import("@/components/elements/AppOverlayLoader"),
      AppPagination: () => import("@/components/elements/AppPagination"),
      AppDropdown: () => import("@/components/elements/AppDropdown"),
      AppSearch: () => import("@/components/elements/AppSearch"),
      AppIconButton: () => import("@/components/elements/AppButton/WithIcon/Other"),
      AppDatepicker: () => import("@/components/elements/AppDatepicker")
    },

    mixins: [planListMixin, companiesMixin, inventoryGroupsMixin, shopsMixin, carClassesMixin, withConfirmation],

    data() {
      return {
        columns,
        selectedPlanId: null,
        showMoreFilters: false
      }
    },

    created() {
      this.debouncedFetchPlans = appDebounce(() => this.fetchPlans())
    },

    beforeRouteLeave(to, _from, next) {
      this.beforeRouteLeaveHandler({ to, next, isChanges: this.$refs.selectedPlan?.hasUnsavedChanges })
    },

    mounted() {
      Promise.all([this.fetchCompanies(), this.fetchInventoryGroups(), this.fetchShops(), this.fetchCarCLasses()]).then(
        () => {
          if (!isEmpty(this.$route.params)) {
            this.setFiltersFromPrams(this.$route.params)
          }
          this.fetchPlans()
        }
      )
    },

    methods: {
      statusLabel({ status }) {
        return this.$t(`ota_management.plan_list.status.${status}`)
      },

      selectPlan({ row }) {
        this.selectedPlanId = row.id
      },

      unselectPlan() {
        this.selectedPlanId = null
        this.fetchPlans()
      },

      setFiltersFromPrams({ company_id }) {
        const company = find(this.companies, ({ id }) => id === company_id)
        const filters = { ...this.filters, companies: [company] }
        this.setFilters(filters)
      },

      switchMoreFilters() {
        this.showMoreFilters = !this.showMoreFilters
      },

      changeSorting([sortingData]) {
        if (isEqual(sortingData, this.sorting)) return

        this.setSorting(sortingData)
        this.fetchPlans()
      },

      clearFilters() {
        this.resetFilters()
        this.debouncedFetchPlans()
      },

      changeFilters(filters) {
        this.setFilters(filters)
        this.resetCurrentPage()
        this.debouncedFetchPlans()
      },

      changePagination(paginationData) {
        this.setPagination({ ...this.pagination, ...paginationData })
        this.fetchPlans()
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/mixins/common.sass"

  .plan-list
    position: relative
    ::v-deep
      .vgt-wrap
        width: max-content

      .table
        width: 100%
        overflow-x: auto
        margin-top: 30px

        &-th
          min-width: 90px !important
          background: $default-gray-light !important

          button
            &:before,
            &:after
              border-left-width: 4px
              border-right-width: 4px


      .suspended
        color: $default-red

        span
          margin-left: 5px
</style>
